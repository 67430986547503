<template>
  <div class="flex h-screen bg-gray-100 dark:bg-gray-800 font-roboto">
    <div
      :class="sidebarOpen ? 'block' : 'hidden'"
      @click="sidebarOpen = false"
      class="fixed z-20 inset-0 bg-black opacity-50 transition-opacity lg:hidden"
    ></div>

    <SideNav :sidebarOpen.sync="sidebarOpen" />

    <div class="flex-1 flex flex-col overflow-hidden">
      <Header :sidebarOpen.sync="sidebarOpen" />

      <router-view></router-view>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SideNav from "@/components/admin/SideNav.vue";
import Header from "@/components/admin/Header.vue";

export default {
  name: "Dashboard",
  components: {
    SideNav,
    Header
  },
  data() {
    return {
      sidebarOpen: true
    };
  },
  mounted() {
    //
  },
  methods: {
    //
  }
};
</script>
