<template>
  <div
    :class="
      sidebarOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'
    "
    class="fixed z-30 inset-y-0 left-0 w-48 transition duration-300 transform bg-white dark:bg-gray-900 overflow-y-auto lg:translate-x-0 lg:static lg:inset-0"
  >
    <div class="flex items-center justify-center mt-8">
      <div class="flex items-center">
        <span class="text-gray-800 dark:text-white text-xl font-semibold"
          >Dashboard</span
        >
      </div>
    </div>

    <nav class="flex flex-col mt-10 px-4 text-center">
      <a
        href="admin/dashboard"
        class="py-2 text-sm text-gray-700 dark:text-gray-100 hover:bg-gray-200 dark:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/dashboard') }"
        >Overview</a
      >
      <a
        href="admin/scheduler"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100  hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/scheduler') }"
        >Scheduler</a
      >
      <a
        href="admin/unique-tickets"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100  hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/unique-tickets') }"
        >Index</a
      >
      <a
        href="admin/bookmark-ticket"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100  hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/bookmark-ticket') }"
        >Saved</a
      >
      <a
        href="admin/tickets"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100  hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/tickets') }"
        >Today's Sales</a
      >
      <a
        href="admin/past"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100  hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/past') }"
        >Past Sales</a
      >
      <a
        href="admin/ticketsshow"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100  hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/ticketsshow') }"
        >Tix Sold in Date
      </a>
      <a
        href="admin/ticket-tn"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/ticket-tn') }"
        >YTD Sales</a
      >
      <a
        href="admin/venue"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/venue') }"
        >Venue</a
      >
      <a
        href="admin/Inventory-counter"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/Inventory-counter') }"
        >Inventory Counter</a
      >
      <a
        href="admin/inventory-changes"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/inventory-changes') }"
        >Inventory Changes</a
      >
      <a
        v-if="isSuperAdmin === 'L1'"
        href="admin/discord-member"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/discord-member') }"
        >Discord Member</a
      >
      <a
        v-if="isSuperAdmin === 'L1'"
        href="admin/add-operator"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/add-operator') }"
        >Add Operator</a
      >
      <a
        v-if="isSuperAdmin === 'L1'"
        href="admin/operators-list"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/operators-list') }"
        >Operator List</a
      >
      <a
        v-if="isSuperAdmin === 'L1'"
        href="admin/password-list"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/password-list') }"
        >Password List</a
      >
      <a
        href="admin/old-tickets"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/old-tickets') }"
        >Old Tickets</a
      >
      <a
        href="admin/resale"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/resale') }"
        >Resale Standard Tickets</a
      >
      <a
        href="admin/resaleresale"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/resaleresale') }"
        >Resale Resale Tickets</a
      >
      <a
        href="admin/onsale"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/onsale') }"
        >Onsale - Presale</a
      >
      <a
        href="admin/craigslist"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/craigslist') }"
        >Craigslist</a
      >
      <a
        href="admin/transfer_script"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/transfer_script') }"
        >Transfer script</a
      >

      <a
        href="admin/favourite_events_new"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/favourite_events_new') }"
        >Favourite Events</a
      >
      <a
        href="admin/favourite_event_calendar"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{
          'active-link': isRouteActive('/admin/favourite_event_calendar')
        }"
        >Favourite Events Calender</a
      >

      <!-- <a
        href="admin/favourite_events"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/favourite_events') }"
        >Favourite Events</a
      >
      <a
        href="admin/favourite_eventsshow"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/favourite_eventsshow') }"
        >Favourite Events Calender</a
      > -->

      <a
        href="admin/favourite_venues"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/favourite_venues') }"
        >Favourite Venues</a
      >

      <a
        href="admin/show_monitor"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/show_monitor') }"
        >Monitor</a
      >

      <a
        href="admin/venuename"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/venuename') }"
        >Lysted
      </a>

      <a
        href="admin/chatsoptify"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/chatsoptify') }"
        >Chat Soptify Daily</a
      >

      <a
        href="admin/chatsoptifylatest"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/chatsoptifylatest') }"
        >Chat Soptify Latest</a
      >

      <a
        href="admin/chatsoptifyglobaldaily"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{
          'active-link': isRouteActive('/admin/chatsoptifyglobaldaily')
        }"
        >Chat Soptify Global Daily</a
      >

      <a
        href="admin/applemusicnewrelease"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/applemusicnewrelease') }"
        >Apple Music New Release</a
      >

      <a
        href="admin/regionalglobalweekly"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{ 'active-link': isRouteActive('/admin/regionalglobalweekly') }"
        >Regional Global Weekly</a
      >

      <a
        href="admin/citytoptrackindianapolisweekly"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        :class="{
          'active-link': isRouteActive('/admin/citytoptrackindianapolisweekly')
        }"
        >City Top Track Indianapolis Weekly</a
      >

      <!-- <a v-on:click="doSomething($event)"
        class="mt-3 py-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        >Transfer script</a 
      > -->
    </nav>
  </div>
</template>

<script>
export default {
  props: ["sidebarOpen"],
  data() {
    return {
      isSuperAdmin: localStorage.getItem("user-role")
    };
  },
  methods: {
    isRouteActive(route) {
      return this.$route.path === route;
    }
    //   doSomething: function doSomething(ev) {
    //      console.log(ev); // MouseEvent { ... }

    //       this.$axios
    //         .get(`https://64.227.185.57/mymail.php`)
    //         .then(res => {

    //           console.log(res);
    //           alert("Your Bot hitting now.");

    //         })
    //         .catch(err => {
    //           console.log(err);
    //           this.loading = "Something went wrong, please try again";
    //         });

    //   }
  }
};
</script>
